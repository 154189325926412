<template>
  <HeaderShape></HeaderShape>
  <section class="relative pt-0 pb-12 bg-[#F8F8F8]">
    <div class="container mx-auto lg:px-5 xl:px-20">
    <!-- HEADER -->
    <div class="mobile-div-header mb-5">
      <base-card class="col-span-5 flex bg-white">
        <h1 class="headingOne self-center text-paragraph-500 font-normal">
          My
          <span class="font-bold">Assic Calling</span>
        </h1>
      </base-card>
    </div>

    <div class="grid grid-cols-1 gap-10 bg-white p-2 rounded mb-6 shadow-xl">
      <div v-if="defaultRoute == false" id="videoSDK" style="height: 600px; background: #e5e5e5;"></div>
      <div v-else-if="defaultRoute == true">
        <Form v-slot="{ handleSubmit }" ref="videoCallForm">
          <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5">
            <div class="grid grid-cols-1 gap-5">
              <div class="form-control w-full mb-1">
                <base-input v-model="form.meeting.meetingId" refKey="form.meeting.meetingId"
                  :rules="requiredRules" required label="Meeting ID"></base-input>
              </div>
              <div class="flex w-full">
                <button class="primaryBtn w-full self-center"
                  @click.prevent="handleSubmit(accessMeeting())">ACCESS MEETING</button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  </section>
</template>

<script setup>
import HeaderShape from "../assets/HeaderShape.vue";
import BaseCard from "../assets/BaseCard.vue";
import BaseInput from "../../src/assets/Form/BaseInput.vue";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { onMounted, ref, reactive } from "vue";
import { useRoute } from 'vue-router'
import { Form } from "vee-validate";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import base from "../services/api";
import { string } from "yup";

let initialForm = {
  meeting: {},
};

const route = useRoute();
const defaultRoute = ref(false);
const routeParameter = ref(false);
const form = reactive(initialForm);
const videoCallForm = ref(null);
const meeting_id = ref('');

if (route.fullPath == '/assic-calling' || route.fullPath == '/join-mobile-meeting') {
  defaultRoute.value = true;
}

if(route.query != null){
  routeParameter.value = true;
} else {
  routeParameter.value = false;
}

const requiredRules = string().required("This field is required");

const meetingID = () => {

  if(route.query.meetingid){
    meeting_id.value = route.query.meetingid;
  }

  return meeting_id.value;
}

const accessMeeting = async () => {

const isValid = await videoCallForm.value.validate();

if (isValid.valid) {
  base
    .post("/meetings/verify-meeting-id/", {
      meetingID: form.meeting.meetingId
    }).then((response) => {
      if (response.data?.message == "True") {
        meeting_id.value = form.meeting.meetingId;
        defaultRoute.value = false;
        initiateMeeting();

        toast('Meeting Successfully Initiated!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast('Meeting ID Not Found!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })
    .catch(() => {
      toast('Unable to Verify Meeting ID!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  } else {
    toast('Please Provide your Meeting ID!', {
      type: 'error',
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}

const initiateMeeting = async () => {
  const apiKey = '14e27e6c-69bd-420b-ad56-c8a889b017af';
  const name = "Guest";

  const config = {
    name: name,
    meetingId: meetingID(),
    apiKey: apiKey,

    containerId: "videoSDK",

    micEnabled: true,
    webcamEnabled: true,
    participantCanToggleSelfWebcam: true,
    participantCanToggleSelfMic: true,

    chatEnabled: true,
    screenShareEnabled: true,
    pollEnabled: true,
    whiteboardEnabled: false,
    raiseHandEnabled: false,

    branding: {
      enabled: true,
      logoURL:
        "https://assichealthmain.b-cdn.net/wp-content/uploads/assic-high-res-logo.png",
      name: "",
      poweredBy: false,
    },

    participantCanLeave: true, // if false, leave button won't be visible

    permissions: {
      askToJoin: false, // Ask joined participants for entry in meeting
      toggleParticipantMic: true, // Can toggle other participant's mic
      toggleParticipantWebcam: true, // Can toggle other participant's webcam
      removeParticipant: true, // Remove other participant from meeting
      endMeeting: true, // End meeting for all participant
      drawOnWhiteboard: false, // Can Draw on whiteboard
      toggleWhiteboard: false, // Can toggle whiteboard
      toggleRecording: false, // Can toggle recording
    },

    joinScreen: {
      visible: true, // Show the join screen ?
      title: "ASSIC Health Professional", // Meeting title
    },

    pin: {
      allowed: true, // participant can pin any participant in meeting
      layout: "SPOTLIGHT", // meeting layout - GRID | SPOTLIGHT | SIDEBAR
    },

    leftScreen: {
      // visible when redirect on leave not provieded
    },
  };
  const meeting = new VideoSDKMeeting();

  meeting.init(config);
}

onMounted(async () => {
  initiateMeeting();
});

</script>
<style>
.mobile-div-header{
  margin-top: -32.75rem;
  margin-top: -530px;
  margin-bottom: 1.25rem;
  display: flex;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  align-content: center;
  gap: 1.25rem;
  background-color: transparent;
}
</style>
