<template>
  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          label="From"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          label="To"
          :minDate="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-4 gap-2 mb-4">
    <button class="btnTab" @click="get6MinWalk(); determineCurrentTab('sixMinWalk')">6 min Walking Test</button>
    <button class="btnTab" @click="get3MinStep(); determineCurrentTab('threeMinStep')">3 min Step Test</button>
    <button class="btnTab" @click="getCycleErgo(); determineCurrentTab('cycleErgo')">Cycle Ergometer Test</button>
    <button class="btnTab" @click="getTreadmillTest(); determineCurrentTab('treadMillTest')">Treadmill Test</button>
    <button class="btnTab" @click="getBeepTest(); determineCurrentTab('beepTest')">Beep Test</button>
    <button class="btnTab" @click="getCooperTest(); determineCurrentTab('cooperTest')">Cooper Test VO2</button>
    <button class="btnTab" @click="getSwimStepTest(); determineCurrentTab('swimStep')">Swimming Step Test</button>
    <button class="btnTab" @click="getShuttleTest(); determineCurrentTab('shuttleTest')">Shuttle Test</button>
    <button class="btnTab" @click="getSwimSprint(); determineCurrentTab('swimSprint')">100m Swim Sprint</button>
    <button class="btnTab" @click="getSitReach(); determineCurrentTab('sitReach')">Sit and Reach</button>
    <button class="btnTab" @click="getStraightLeg(); determineCurrentTab('straightLeg')">Straight Leg Raise</button>
    <button class="btnTab" @click="getFloorTouch(); determineCurrentTab('floorTouch')">Floor Touch Test</button>
    <button class="btnTab" @click="getThomasTest(); determineCurrentTab('thomasTest')">Thomas Test</button>
    <button class="btnTab" @click="getTrunkLift(); determineCurrentTab('trunkLift')">Trunk Lift Test</button>
    <button class="btnTab" @click="get2MinCrunch(); determineCurrentTab('twoMinCrunch')">2 min Crunch (Sit Up)</button>
    <button class="btnTab" @click="getPushUp(); determineCurrentTab('pushUp')">Push up Test</button>
    <button class="btnTab" @click="getDips(); determineCurrentTab('dips')">Dips</button>
    <button class="btnTab" @click="getBurpees(); determineCurrentTab('burpees')">Burpees</button>
    <button class="btnTab" @click="getSquats(); determineCurrentTab('squats')">Squats</button>
    <button class="btnTab" @click="getSwimBeepTest(); determineCurrentTab('swimBeep')">Swimming Beep Test</button>
    <button class="btnTab" @click="getBallThrow(); determineCurrentTab('ballThrow')">Ball Throw Test</button>
    <button class="btnTab" @click="getPowerBall(); determineCurrentTab('powerBall')">Power Ball Chest Launch</button>
    <button class="btnTab" @click="getShotPut(); determineCurrentTab('shotPut')">Shot Put Back Throw</button>
    <button class="btnTab" @click="getCricketBaseball(); determineCurrentTab('cricketBaseball')">Cricket/Baseball Throw</button>
    <button class="btnTab" @click="getWingate(); determineCurrentTab('wingate')">Wingate Test</button>
    <button class="btnTab" @click="getVerticalJump(); determineCurrentTab('verticalJump')">Vertical Jump</button>
    <button class="btnTab" @click="getBroadLongJump(); determineCurrentTab('broadLongJump')">Broad Long Jump</button>
    <button class="btnTab" @click="get3Hop(); determineCurrentTab('threeHop')">3 Hop Test</button>
    <button class="btnTab" @click="getPowerPunch(); determineCurrentTab('powerBunch')">Power Punch</button>
    <button class="btnTab" @click="getMargaria(); determineCurrentTab('margaria')">Margaria Kalamen Test</button>
    <button class="btnTab" @click="getBenchPress(); determineCurrentTab('benchPress')">Bench Press 1RM</button>
    <button class="btnTab" @click="getLegPress(); determineCurrentTab('legPress')">Leg Press 6RM</button>
    <button class="btnTab" @click="getPlank(); determineCurrentTab('plank')">Plank</button>
    <button class="btnTab" @click="getAgilityT(); determineCurrentTab('agilityT')">Agility T Test</button>
    <button class="btnTab" @click="getIllinoisAgility(); determineCurrentTab('illinoisAgility')">Illinois Agility Test</button>
    <button class="btnTab" @click="getEdgrenStep(); determineCurrentTab('edgrenStep')">Edgren Side Step Test</button>
    <button class="btnTab" @click="getFigure8(); determineCurrentTab('figureEight')">Figure 8 Test</button>
    <button class="btnTab" @click="getSitStand(); determineCurrentTab('sitStand')">Sit to Stand</button>
    <button class="btnTab" @click="getSquats2(); determineCurrentTab('squatTwo')">Squats</button>
    <button class="btnTab" @click="getLunges(); determineCurrentTab('lunges')">Lunges</button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import moment from "moment";

  import { Chart, registerables } from "chart.js";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);


  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };

  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const sixMinWalkTab = ref(true);
  const threeMinStepTab = ref(false);
  const cycleErgoTab = ref(false);
  const treadMillTestTab = ref(false);
  const beepTestTab = ref(false);
  const cooperTestTab = ref(false);
  const swimStepTab = ref(false);
  const shuttleTestTab = ref(false);
  const swimSprintTab = ref(false);
  const sitReachTab = ref(false);
  const straightLegTab = ref(false);
  const floorTouchTab = ref(false);
  const thomasTestTab = ref(false);
  const trunkLiftTab = ref(false);
  const twoMinCrunchTab = ref(false);
  const pushUpTab = ref(false);
  const dipsTab = ref(false);
  const burpeesTab = ref(false);
  const squatsTab = ref(false);
  const swimBeepTab = ref(false);
  const ballThrowTab = ref(false);
  const powerBallTab = ref(false);
  const shotPutTab = ref(false);
  const cricketBaseballTab = ref(false);
  const wingateTab = ref(false);
  const verticalJumpTab = ref(false);
  const broadLongJumpTab = ref(false);
  const threeHopTab = ref(false);
  const powerBunchTab = ref(false);
  const margariaTab = ref(false);
  const benchPressTab = ref(false);
  const legPressTab = ref(false);
  const plankTab = ref(false);
  const agilityTTab = ref(false);
  const illinoisAgilityTab = ref(false);
  const edgrenStepTab = ref(false);
  const figureEightTab = ref(false);
  const sitStandTab = ref(false);
  const squatTwoTab = ref(false);
  const lungesTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const generateDataSet = async (label,
    data,
    backgroundColor = "#F04248",
    borderColor = "#F04248") => {
      graphData.value = {
          label: label,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          data: data,
          fill: false
        }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
    myLine.data.datasets = datasets.value;
    myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

    /*
      This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
      when adding data to customizing dates
    */
    if(tabType == "sixMinWalk"){
      sixMinWalkTab.value = true;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "threeMinStep"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = true;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "cycleErgo"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = true;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "treadMillTest"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = true;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "beepTest"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = true;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "cooperTest"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = true;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "swimStep"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = true;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "shuttleTest"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = true;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "swimSprint"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = true;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "sitReach"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = true;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "straightLeg"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = true;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "floorTouch"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = true;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "thomasTest"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = true;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "trunkLift"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = true;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "twoMinCrunch"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = true;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "pushUp"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = true;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "dips"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = true;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "burpees"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = true;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "squats"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = true;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "swimBeep"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = true;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "ballThrow"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = true;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "powerBall"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = true;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "shotPut"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = true;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "cricketBaseball"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = true;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "wingate"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = true;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "verticalJump"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = true;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "broadLongJump"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = true;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "threeHop"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = true;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "powerBunch"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = true;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "margaria"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = true;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "benchPress"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = true;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "legPress"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = true;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "plank"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = true;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "agilityT"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = true;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "illinoisAgility"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = true;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "edgrenStep"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = true;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "figureEight"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = true;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "sitStand"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = true;
      squatTwoTab.value = false;
      lungesTab.value = false;
    }
    if(tabType == "squatTwo"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = true;
      lungesTab.value = false;
    }
    if(tabType == "lunges"){
      sixMinWalkTab.value = false;
      threeMinStepTab.value = false;
      cycleErgoTab.value = false;
      treadMillTestTab.value = false;
      beepTestTab.value = false;
      cooperTestTab.value = false;
      swimStepTab.value = false;
      shuttleTestTab.value = false;
      swimSprintTab.value = false;
      sitReachTab.value = false;
      straightLegTab.value = false;
      floorTouchTab.value = false;
      thomasTestTab.value = false;
      trunkLiftTab.value = false;
      twoMinCrunchTab.value = false;
      pushUpTab.value = false;
      dipsTab.value = false;
      burpeesTab.value = false;
      squatsTab.value = false;
      swimBeepTab.value = false;
      ballThrowTab.value = false;
      powerBallTab.value = false;
      shotPutTab.value = false;
      cricketBaseballTab.value = false;
      wingateTab.value = false;
      verticalJumpTab.value = false;
      broadLongJumpTab.value = false;
      threeHopTab.value = false;
      powerBunchTab.value = false;
      margariaTab.value = false;
      benchPressTab.value = false;
      legPressTab.value = false;
      plankTab.value = false;
      agilityTTab.value = false;
      illinoisAgilityTab.value = false;
      edgrenStepTab.value = false;
      figureEightTab.value = false;
      sitStandTab.value = false;
      squatTwoTab.value = false;
      lungesTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(sixMinWalkTab.value == true){
      get6MinWalk();
    }
    if(threeMinStepTab.value == true){
      get3MinStep();
    }
    if(cycleErgoTab.value == true){
      getCycleErgo();
    }
    if(treadMillTestTab.value == true){
      getTreadmillTest();
    }
    if(beepTestTab.value == true){
      getBeepTest();
    }
    if(cooperTestTab.value == true){
      getCooperTest();
    }
    if(swimStepTab.value == true){
      getSwimStepTest();
    }
    if(shuttleTestTab.value == true){
      getShuttleTest();
    }
    if(swimSprintTab.value == true){
      getSwimSprint();
    }
    if(sitReachTab.value == true){
      getSitReach();
    }
    if(straightLegTab.value == true){
      getStraightLeg();
    }
    if(floorTouchTab.value == true){
      getFloorTouch();
    }
    if(thomasTestTab.value == true){
      getThomasTest();
    }
    if(trunkLiftTab.value == true){
      getTrunkLift();
    }
    if(twoMinCrunchTab.value == true){
      get2MinCrunch();
    }
    if(pushUpTab.value == true){
      getPushUp();
    }
    if(dipsTab.value == true){
      getDips();
    }
    if(burpeesTab.value == true){
      getBurpees();
    }
    if(squatsTab.value == true){
      getSquats();
    }
    if(swimBeepTab.value == true){
      getSwimBeepTest();
    }
    if(ballThrowTab.value == true){
      getBallThrow();
    }
    if(powerBallTab.value == true){
      getPowerBall();
    }
    if(shotPutTab.value == true){
      getShotPut();
    }
    if(cricketBaseballTab.value == true){
      getCricketBaseball();
    }
    if(wingateTab.value == true){
      getWingate();
    }
    if(verticalJumpTab.value == true){
      getVerticalJump();
    }
    if(broadLongJumpTab.value == true){
      getBroadLongJump();
    }
    if(threeHopTab.value == true){
      get3Hop();
    }
    if(powerBunchTab.value == true){
      getPowerPunch();
    }
    if(margariaTab.value == true){
      getMargaria();
    }
    if(benchPressTab.value == true){
      getBenchPress();
    }
    if(legPressTab.value == true){
      getLegPress();
    }
    if(plankTab.value == true){
      getPlank();
    }
    if(agilityTTab.value == true){
      getAgilityT();
    }
    if(illinoisAgilityTab.value == true){
      getIllinoisAgility();
    }
    if(edgrenStepTab.value == true){
      getEdgrenStep();
    }
    if(figureEightTab.value == true){
      getFigure8();
    }
    if(sitStandTab.value == true){
      getSitStand();
    }
    if(squatTwoTab.value == true){
      getSquats2();
    }
    if(lungesTab.value == true){
      getLunges();
    }
  };

  const get6MinWalk = async () => {
    base
      .post(`/profiles/special/get/38/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { walking_test_distance } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(walking_test_distance));
          });

          generateDataSet("6 min Walk Test (m)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );
          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("get6MinWalk Error");
      });
  };

  const get3MinStep = async () => {
    base
      .post(`/profiles/special/get/39/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { step_test_VO2 } = d;
            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(step_test_VO2));
          });

        generateDataSet("3 Min Step Test", dataSeriesList);

        datasets.value.push(
          graphData.value
        );

        updateChart();
      } else {
        toast('No data found!', {
          type: 'warning',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })
    .catch(() => {
      console.error("get3MinStep Error");
    });
  };

  const getCycleErgo = async () => {
    base
      .post(`/profiles/special/get/40/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { ergometer_test_VO2 } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(ergometer_test_VO2));
          });

          generateDataSet("Cycle Ergometer Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getCycleErgo Error");
      });
  };

  const getTreadmillTest = async () => {
    base
      .post(`/profiles/special/get/41/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { treadmill_test_VO2 } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(treadmill_test_VO2));
          });

          generateDataSet("Treadmill Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getTreadmillTest Error");
      });
  };

  const getBeepTest = async () => {
    base
      .post(`/profiles/special/get/42/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { beep_level } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(beep_level));
          });

          generateDataSet("Beep Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBeepTest Error");
      });
  };

  const getCooperTest = async () => {
    base
      .post(`/profiles/special/get/43/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { cooper_test_VO2 } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(cooper_test_VO2));
          });

          generateDataSet("12 min Cooper Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getCooperTest Error");
      });
  };

  const getSwimStepTest = async () => {
    base
      .post(`/profiles/special/get/44/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { swim_step_test_HR } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(swim_step_test_HR));
          });

          generateDataSet(
              "Swimming Step Test (bpm)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSwimStepTest Error");
      });
  };

  const getShuttleTest = async () => {
    base
      .post(`/profiles/special/get/45/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { shuttle_test_time } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(shuttle_test_time));
          });

          generateDataSet("Shuttle Test(s)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getShuttleTest Error");
      });
  };

  const getSwimSprint = async () => {
    base
      .post(`/profiles/special/get/46/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { swim_sprint_speed } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(swim_sprint_speed));
          });

          generateDataSet(
              "100m Swim Sprint (m/s)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSwimSprint Error");
      });
  };

  const getSitReach = async () => {
    base
      .post(`/profiles/special/get/47/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { sit_and_reach } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(sit_and_reach));
          });

          generateDataSet("Sit and Reach (cm)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSitReach Error");
      });
  };

  const getStraightLeg = async () => {
    base
      .post(`/profiles/special/get/48/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { leg_raise_degrees } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(leg_raise_degrees));
          });

          generateDataSet(
              "Stright Leg Raise (degrees)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getStraightLeg Error");
      });
  };

  const getFloorTouch = async () => {
    base
      .post(`/profiles/special/get/49/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { floor_touch_degrees } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(floor_touch_degrees));
          });

          generateDataSet(
              "Floor Touch Test (degrees)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getFloorTouch Error");
      });
  };

  const getThomasTest = async () => {
    base
      .post(`/profiles/special/get/50/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { thomas_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(thomas_test));
          });

          generateDataSet("Thomas Test: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getThomasTest Error");
      });
  };

  const getTrunkLift = async () => {
    base
      .post(`/profiles/special/get/51/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { trunk_lift_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(trunk_lift_test));
          });

          generateDataSet("Trunk Lift Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getTrunkLift Error");
      });
  };

  const get2MinCrunch = async () => {
    base
      .post(`/profiles/special/get/52/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { crunches } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(crunches));
          });

          generateDataSet(
              "2 min Crunches (reps/min)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("get2MinCrunch Error");
      });
  };

  const getPushUp = async () => {
    base
      .post(`/profiles/special/get/53/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { push_up_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(push_up_test));
          });

          generateDataSet("Push up Test ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getPushUp Error");
      });
  };

  const getDips = async () => {
    base
      .post(`/profiles/special/get/54/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { dips } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(dips));
          });

          generateDataSet("Dips ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getDips Error");
      });
  };

  const getBurpees = async () => {
    base
      .post(`/profiles/special/get/55/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { burpees } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(burpees));
          });

          generateDataSet("Burpees ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBurpees Error");
      });
  };

  const getSquats = async () => {
    base
      .post(`/profiles/special/get/56/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { squats } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(squats));
          });

          generateDataSet("Squats ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSquats Error");
      });
  };

  const getSwimBeepTest = async () => {
    base
      .post(`/profiles/special/get/57/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { swim_beep_level } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(swim_beep_level));
          });

          generateDataSet("Swimming Beep Level ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSwimBeepTest Error");
      });
  };

  const getBallThrow = async () => {
    base
      .post(`/profiles/special/get/58/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { ball_throw_distance } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(ball_throw_distance));
          });

          generateDataSet("Ball Throw (m)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBallThrow Error");
      });
  };

  const getPowerBall = async () => {
    base
      .post(`/profiles/special/get/59/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { power_ball_dist } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(power_ball_dist));
          });

          generateDataSet("Ball Throw (m)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getPowerBall Error");
      });
  };

  const getShotPut = async () => {
    base
      .post(`/profiles/special/get/60/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { shot_back_dist } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(shot_back_dist));
          });

          generateDataSet(
              "Shotput back throw (m)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getShotPut Error");
      });
  };

  const getCricketBaseball = async () => {
    base
      .post(`/profiles/special/get/61/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { baseball_throw_dist } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(baseball_throw_dist));
          });

          generateDataSet(
              "Cricket/Baseball Throw (m)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getCricketBaseball Error");
      });
  };

  const getWingate = async () => {
    base
      .post(`/profiles/special/get/62/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { wingate_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(wingate_test));
          });

          generateDataSet("Wingate (kpm/min)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getWingate Error");
      });
  };

  const getVerticalJump = async () => {
    base
      .post(`/profiles/special/get/63/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { vertical_jump } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(vertical_jump));
          });

          generateDataSet("Vertical Jump (cm)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getVerticalJump Error");
      });
  };

  const getBroadLongJump = async () => {
    base
      .post(`/profiles/special/get/64/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { broad_long_jump } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(broad_long_jump));
          });

          generateDataSet("Broad Long Jump (m)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBroadLongJump Error");
      });
  };

  const get3Hop = async () => {
    base
      .post(`/profiles/special/get/65/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { hop_test_cm } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(hop_test_cm));
          });

          generateDataSet("3 Hop Test (cm)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("get3Hop Error");
      });
  };

  const getPowerPunch = async () => {
    base
      .post(`/profiles/special/get/66/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { power_punch } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(power_punch));
          });

          generateDataSet("Power Punch ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getPowerPunch Error");
      });
  };

  const getMargaria = async () => {
    base
      .post(`/profiles/special/get/67/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { mk_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(mk_test));
          });

          generateDataSet(
              "Margaria Kalamen (watts)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getMargaria Error");
      });
  };

  const getBenchPress = async () => {
    base
      .post(`/profiles/special/get/68/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { bench_press_1rm } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(bench_press_1rm));
          });

          generateDataSet("Bench Press (kg)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBenchPress Error");
      });
  };

  const getLegPress = async () => {
    base
      .post(`/profiles/special/get/69/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { leg_press_6rm } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(leg_press_6rm));
          });

          generateDataSet("Leg Press (kg)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getLegPress Error");
      });
  };

  const getPlank = async () => {
    base
      .post(`/profiles/special/get/70/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { plank_min } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(plank_min));
          });

          generateDataSet("Plank (min)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getPlank Error");
      });
  };

  const getAgilityT = async () => {
    base
      .post(`/profiles/special/get/71/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { t_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(t_test));
          });

          generateDataSet("Agility Test(s)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getAgilityT Error");
      });
  };

  const getIllinoisAgility = async () => {
    base
      .post(`/profiles/special/get/72/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { illinois_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(illinois_test));
          });

          generateDataSet(
              "Illinois Agility Test(s)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getIllinoisAgility Error");
      });
  };

  const getEdgrenStep = async () => {
    base
      .post(`/profiles/special/get/73/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { edgren_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(edgren_test));
          });

          generateDataSet(
              "Edgren Side Step Test(s)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getEdgrenStep Error");
      });
  };

  const getFigure8 = async () => {
    base
      .post(`/profiles/special/get/74/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { figure_8_test } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(figure_8_test));
          });

          generateDataSet("Figure 8 Test(s)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getFigure8 Error");
      });
  };

  const getSitStand = async () => {
    base
      .post(`/profiles/special/get/75/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { sit_to_stand } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(sit_to_stand));
          });

          generateDataSet("Sit to Stand(s)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSitStand Error");
      });
  };

  const getSquats2 = async () => {
    base
      .post(`/profiles/special/get/76/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { squats } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(squats));
          });

          generateDataSet("Squats (reps)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getSquats2 Error");
      });
  };

  const getLunges = async () => {
    base
      .post(`/profiles/special/get/77/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { lunges } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(lunges));
          });

          generateDataSet("Lunges (reps)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getLunges Error");
      });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      get6MinWalk();
    });
  });

</script>

<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}

.chart {
  background-color: #efefef;
}
</style>
