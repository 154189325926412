<template>
  <NavComponent></NavComponent>
  <HeaderShape></HeaderShape>
  <section class="relative pt-5 pb-12 bg-[#F8F8F8]" v-if="!user">
    <MeetingComponent></MeetingComponent>
  </section>
  <section class="relative pt-5 pb-12 bg-[#F8F8F8]" v-if="user">
    <MeetingDashboardComponent></MeetingDashboardComponent>
  </section>

  <FooterComponent></FooterComponent>
</template>

<script setup>
  import NavComponent from "../components/NavComponent.vue";
  import HeaderShape from "../assets/HeaderShape.vue";
  import MeetingComponent from "../components/meeting/MeetingComponent.vue";
  import MeetingDashboardComponent from "../components/meeting/MeetingDashboardComponent.vue";
  import FooterComponent from "../components/FooterComponent.vue";

  import { useStore } from "vuex";
  import { computed } from "@vue/runtime-core";
  import 'vue3-toastify/dist/index.css';

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

</script>
