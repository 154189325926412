<template>
  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          label="From"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          label="To"
          :minDate="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-4 gap-2 mb-4">
    <button class="btnTab" @click="getRecoveryHR(); determineCurrentTab('RecoveryHR')">
      Recovery HR
      Data
    </button>
    <button class="btnTab" @click="getRestingHR(); determineCurrentTab('RestingHR')">
      Resting Heart Rate
      Data
    </button>
    <button class="btnTab" @click="getFitness(); determineCurrentTab('Fitness')">
      Workout
    </button>
    <button class="btnTab" @click="getBloodPressure(); determineCurrentTab('BloodPressure')">
      Blood Pressure
      Data
    </button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import moment from "moment";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  import { Chart, registerables } from "chart.js";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);


  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };


  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  //const dataArr = ref([]);
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);
  const dataSeriesList2 = ref(null);
  const dataSeriesList3 = ref(null);
  const dataSeriesList4 = ref(null);
  const dataSeriesList5 = ref(null);
  const dataSeriesList6 = ref(null);
  const dataSeriesList7 = ref(null);
  const dataSeriesList8 = ref(null);
  const dataSeriesList9 = ref(null);
  const dataSeriesList10 = ref(null);
  const dataSeriesList11 = ref(null);
  const dataSeriesList12 = ref(null);
  const dataSeriesList13 = ref(null);
  const dataSeriesList14 = ref(null);
  const dataSeriesList15 = ref(null);
  const dataSeriesList16 = ref(null);
  const dataSeriesList17 = ref(null);
  const dataSeriesList18 = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const recoveryHRTab = ref(true);
  const restingHRTab = ref(false);
  const fitnessTab = ref(false);
  const bloodPressureTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
    fromDate.value = formatISO(firstDayYear);
    toDate.value = formatISO(new Date);
    refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const generateDataSet = async (label,
      data,
      backgroundColor = "#F04248",
      borderColor = "#F04248") => {
        graphData.value = {
            label: label,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: data,
            fill: false
          }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
      // myLine.value.data.datasets[0].data = dataSeriesList.value;
      myLine.data.datasets = datasets.value;
      myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

    /*
      This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
      when adding data to customizing dates
    */

    if(tabType == "RecoveryHR"){
      recoveryHRTab.value = true;
      restingHRTab.value = false;
      fitnessTab.value = false;
      bloodPressureTab.value = false;
    }
    if(tabType == "RestingHR"){
      recoveryHRTab.value = false;
      restingHRTab.value = true;
      fitnessTab.value = false;
      bloodPressureTab.value = false;
    }
    if(tabType == "Fitness"){
      recoveryHRTab.value = false;
      restingHRTab.value = false;
      fitnessTab.value = true;
      bloodPressureTab.value = false;
    }
    if(tabType == "BloodPressure"){
      recoveryHRTab.value = false;
      restingHRTab.value = false;
      fitnessTab.value = false;
      bloodPressureTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(recoveryHRTab.value == true){
      getRecoveryHR();
    }
    if(restingHRTab.value == true){
      getRestingHR();
    }
    if(fitnessTab.value == true){
      getFitness();
    }
    if(bloodPressureTab.value == true){
      getBloodPressure();
    }
  };

  const getRecoveryHR = async () => {
    base
      .post(`/v2/fitness/a-recovery-hr-get-custom-date-range/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          dataSeriesList4.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              recovery_hr_1,
              recovery_hr_2,
              recovery_hr_3,
              recovery_hr_4,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(recovery_hr_1));
            dataSeriesList2.value.push(parseInt(recovery_hr_2));
            dataSeriesList3.value.push(parseInt(recovery_hr_3));
            dataSeriesList4.value.push(parseInt(recovery_hr_4));
          });

          generateDataSet("1st Reading", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "2nd Reading",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "3rd Reading",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "4th Reading",
            dataSeriesList4,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getRecoveryHR Error");
      });
  };

  const getRestingHR = async () => {
    base
      .post(`/v2/fitness/resting-hr-date-range/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { rested_hr } = d;

              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(rested_hr));
            });

            generateDataSet(
              "Resting Heart Rate (bpm)",
              dataSeriesList
            )

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getRestingHR Error");
        });
  };

  const getFitness = async () => {
    base
      .post(`/v2/fitness/fitness-get-custom-date-range/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;
            categoriesList.value = [];
            dataSeriesList.value = [];
            dataSeriesList2.value = [];
            dataSeriesList3.value = [];
            dataSeriesList4.value = [];
            dataSeriesList5.value = [];
            dataSeriesList6.value = [];
            dataSeriesList7.value = [];
            dataSeriesList8.value = [];
            dataSeriesList9.value = [];
            dataSeriesList10.value = [];
            dataSeriesList11.value = [];
            dataSeriesList12.value = [];
            dataSeriesList13.value = [];
            dataSeriesList14.value = [];
            dataSeriesList15.value = [];
            dataSeriesList16.value = [];
            dataSeriesList17.value = [];
            dataSeriesList18.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const {
              exercise_hr_1,
              exercise_hr_2,
              exercise_hr_3,
              exercise_hr_4,
              baseline_exercise_hr_1,
              baseline_exercise_hr_2,
              baseline_exercise_hr_3,
              baseline_exercise_hr_4,
              intensity_vo2max,
              baseline_intensity_vo2max,
              recovery_hr_1,
              recovery_hr_2,
              recovery_hr_3,
              recovery_hr_4,
              baseline_recovery_hr_1,
              baseline_recovery_hr_2,
              baseline_recovery_hr_3,
              baseline_recovery_hr_4, } = d;

              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(exercise_hr_1));
              dataSeriesList2.value.push(parseInt(exercise_hr_2));
              dataSeriesList3.value.push(parseInt(exercise_hr_3));
              dataSeriesList4.value.push(parseInt(exercise_hr_4));
              dataSeriesList5.value.push(parseInt(baseline_exercise_hr_1));
              dataSeriesList6.value.push(parseInt(baseline_exercise_hr_2));
              dataSeriesList7.value.push(parseInt(baseline_exercise_hr_3));
              dataSeriesList8.value.push(parseInt(baseline_exercise_hr_4));
              dataSeriesList9.value.push(parseFloat(intensity_vo2max));
              dataSeriesList10.value.push(parseFloat(baseline_intensity_vo2max));
              dataSeriesList11.value.push(parseInt(recovery_hr_1));
              dataSeriesList12.value.push(parseInt(recovery_hr_2));
              dataSeriesList13.value.push(parseInt(recovery_hr_3));
              dataSeriesList14.value.push(parseInt(recovery_hr_4));
              dataSeriesList15.value.push(parseInt(baseline_recovery_hr_1));
              dataSeriesList16.value.push(parseInt(baseline_recovery_hr_2));
              dataSeriesList17.value.push(parseInt(baseline_recovery_hr_3));
              dataSeriesList18.value.push(parseInt(baseline_recovery_hr_4));
            });

            generateDataSet(
              "Exercise HR 1st Reading (bpm)",
              dataSeriesList
            )

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Exercise HR 2nd Reading (bpm)",
              dataSeriesList2,
              "#058EF0",
              "#058EF0"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Exercise HR 3rd Reading (bpm)",
              dataSeriesList3,
              "#FFD447",
              "#FFD447"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Exercise HR 4th Reading (bpm)",
              dataSeriesList4,
              "#7BC559",
              "#7BC559"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Intensity VO2 Max (%)",
              dataSeriesList9,
              "#FF8552",
              "#FF8552"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Recovery HR 1st Reading (bpm)",
              dataSeriesList11,
              "#136F63",
              "#136F63"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Recovery HR 2nd Reading (bpm)",
              dataSeriesList12,
              "#E072A4",
              "#E072A4"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Recovery HR 3rd Reading (bpm)",
              dataSeriesList13,
              "#6883BA",
              "#6883BA"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Recovery HR 4th Reading (bpm)",
              dataSeriesList14,
              "#3D3B8E",
              "#3D3B8E"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Exercise HR 1st Reading (bpm)",
              dataSeriesList5,
              "#7CAFC4",
              "#7CAFC4"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Exercise HR 2nd Reading (bpm)",
              dataSeriesList6,
              "#5995ED",
              "#5995ED"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Exercise HR 3rd Reading (bpm)",
              dataSeriesList7,
              "#13262F",
              "#13262F"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Exercise HR 4th Reading (bpm)",
              dataSeriesList8,
              "#C5AFA4",
              "#C5AFA4"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Intensity VO2 Max (%)",
              dataSeriesList10,
              "#664147",
              "#664147"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Recovery HR 1st Reading (bpm)",
              dataSeriesList15,
              "#B9B8D3",
              "#B9B8D3"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Recovery HR 2nd Reading (bpm)",
              dataSeriesList16,
              "#1F5673",
              "#1F5673"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Recovery HR 3rd Reading (bpm)",
              dataSeriesList17,
              "#8E6E53",
              "#8E6E53"
            );

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Baseline Recovery HR 4th Reading (bpm)",
              dataSeriesList18,
              "#C69C72",
              "#C69C72"
            );

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getRestingHR Error");
        });
  };

  const getBloodPressure = async () => {
    base
      .post(`/v2/fitness/bp-get-custom-date-range/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;
          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { systolic_value, diastolic_value } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(systolic_value));
            dataSeriesList2.value.push(parseInt(diastolic_value));
          });

          generateDataSet("Systolic Value (mmHg)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Diastolic Value (mmHg)",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );
          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getBloodPressure Error");
      });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      getRecoveryHR();
    });
  });

</script>


<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}

.chart {
  background-color: #efefef;
}
</style>
