<template>
  <NavComponent></NavComponent>
  <div class="grid grid-cols-5 h-screen">
    <!-- div left -->
    <div class="relative col-span-2" id="hero-image">
      <img
        class="h-full object-cover hero-image"
        src="../assets/img/assic-learning-slide.jpg"
        alt="Assic Heart monitor"
      />
    </div>

    <!-- div right -->
    <div class="flex w-full h-full justify-center bg-gray-100 col-span-3 pt-24">
      <div class="self-center w-5/5 lg:w-3/5 xl:w-3/5">
        <div class="text-center">
          <img
            class="mx-auto w-20"
            src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/performance-fingerprint-app.png"
            alt="Assic Logo"
          />
          <h1 class="text-lg font-bold text-secondary-500">PERFORMANCE FINGERPRINT</h1>
        </div>
        <div class="text-center">
          <h1 class="text-lg font-bold text-primary-500">LOGIN</h1>
        </div>

        <div class="grid gap-y-6 py-5">
          <hr class="col-span-4 self-center border-t-2 border-grey-200 mx-8 mb-3" />
        </div>

        <Form
          as="div"
          v-slot="{ handleSubmit }"
          ref="loginForm"
          class="mx-8"
        >
          <div class="bg-[#F8F8F8] shadow-md rounded p-5">
            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.email"
                refKey="form.user.email"
                :rules="requiredRules"
                required
                label="Email Address"
              ></base-input>
              <div
                class="error text-xs text-secondary-700"
                v-if="errors.email"
              >{{ errors.email }}</div>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.password"
                :rules="requiredRules"
                refKey="form.user.password"
                required
                autocomplete="new-password"
                noDetails
                label="Password"
                :type="passwordVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="passwordVisible = !passwordVisible"
                    type="button"
                  >
                    <EyeIcon
                      v-if="passwordVisible"
                      class="w-6 h6"
                    />
                    <EyeSlashIcon
                      v-else
                      class="w-6 h6"
                    />
                  </button>
                </template>
              </base-input>
              <div
                class="error text-xs text-secondary-700"
                v-if="errors.password"
              >{{ errors.password }}</div>
            </div>
          </div>

          <div class="grid gap-y-6 mt-6">
            <button
              class="primaryBtn w-40 mx-auto disabled:btn-ghost disabled:btn-active text-white"
              @click="handleSubmit(onLogin())"
              type="submit"
            >LOG IN</button>

            <!-- </router-link>s -->
            <hr class="border-1 border-grey-200" />

            <div class="mt-5 mb-5 text-base">
              <div class="grid justify-center mb-3">
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                  class="text-secondary-500 font-semibold"
                >Lost
                  Password?</router-link>
              </div>
              <div class="grid justify-center">
                <router-link
                  to="/register"
                  class="text-secondary-500 font-semibold"
                >Don't have an account? Register
                  now</router-link>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import NavComponent from "../components/NavComponent.vue";
import { mapState, mapActions, useStore } from "vuex";
import { useRouter } from "vue-router";

//import LoginValidations from "../services/LoginValidations";

import { ref, reactive, inject } from 'vue';
import { string } from "yup";

import BaseInput from "../../src/assets/Form/BaseInput.vue";
import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/24/solid';
import { Form } from "vee-validate";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {

  components: {
    NavComponent,
    BaseInput,
    EyeSlashIcon,
    EyeIcon,
    Form,
  },

  setup() {
    let initialForm = {
      user: {},
    };

    const store = useStore();
    const router = useRouter();

    const form = reactive(initialForm);
    const loginForm = ref(null);
    const API = inject("axios");

    const password = ref();
    const email = ref();
    const errors = ref([]);
    const passwordVisible = ref(false);
    const passwordConfirmVisible = ref(false);

    const requiredRules = string().required("This field is required");

    const onLogin = async () => {
      const isValid = await loginForm.value.validate();
      if (isValid.valid) {

        API.post("/v2/user/login/", {
          email: form.user.email,
          password: form.user.password,
        })
          .then((response) => {
            if (response.data?.status === "success") {
              store.dispatch("auth/setUser", response.data.details);
              router.push({ path: "/dashboard" });
            } else {
              toast(response.data?.details.details[0], {
                  type: 'error',
                  position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          })
          .catch(() => {
              toast('Unable Login!', {
                type: 'error',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      }
    };

    return {
      passwordVisible,
      password,
      email,
      errors,
      requiredRules,
      form,
      passwordConfirmVisible,
      onLogin,
      loginForm
    };
  },

  computed: {
    ...mapState("auth", { name: (state) => state.name }),

    user() {
      return this.$store.state.user;
    },
  },

  created() {
    this.setUser(null);
    if (this.user) {
      this.$router.push("/dashboard");
    }
  },

  methods: {
    ...mapActions({
      setUser: "auth/setUser",
    }),
  },
};
</script>

<style scoped>
  #hero-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
</style>

