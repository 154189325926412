<template>
  <!-- Invite Doctor -->
  <ConfirmationModal v-if="inviteDoctorModal === true">
    <template v-slot:header>Invite Doctor</template>
    <template v-slot:content>
      <div class="form-control w-full mb-3">
        <p class="mx-auto">By sending this invite, you are giving consent for the doctor to view your medical data.</p>
      </div>
      <div class="form-control w-full mb-3">
        <base-input
          v-model="doctorEmail"
          refKey="doctorEmail"
          label="Doctor's Email"
          required
        ></base-input>
      </div>
    </template>
    <template v-slot:footer>
      <button
        class="secondaryBtn w-40 mx-5"
        @click="inviteDoctorModal = false"
      >Close</button>
      <button
        class="primaryBtn w-40"
        @click="inviteDoctor();"
      >
        Invite
      </button>
    </template>
    <template v-slot:background>
      <div
        v-if="inviteDoctorModal === true"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>

  <div class="container mx-auto">
    <div class="grid grid-cols-4 gap-5">
      <div class="form-control w-full mb-1">
        <h1 class="headingOne text-2xl font-bold text-[#161922] mb-3">My Doctors ({{ doctorList.length }})</h1>
      </div>
      <div class="flex w-full"></div>
      <div class="flex w-full">
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          style="margin-left: auto; margin-right: 0;width:100%;"
          @click="inviteDoctorModal = true"
        >
          Invite Doctor
        </button>
      </div>
      <div class="w-full mb-1">
        <button
          v-if="doctorsSection == false"
          @click="doctorsSection = true"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
          :disabled="!doctorList.length"
        >
          Display
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/More.svg"
          />
        </button>
        <button
          v-else-if="doctorsSection == true"
          @click="doctorsSection = false;"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
        >
          Hide
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/Hide.svg"
          />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5" v-if="doctorsSection == true">
      <div class="w-full">
        <div class="bg-white rounded p-5 mb-6 shadow-xl">
          <!-- YOUR ROUTINES -->
          <div class="p-5 mx-auto">
            <div class="bg-white rounded grid grid-cols-1 gap-10">
              <div
                  v-for="doctorObject in doctorList"
                  :key="doctorObject.doctor.id"
                  :doctorObject="doctorObject"
              >
                <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                  <div class="grid grid-cols-3 gap-2">
                    <div class="grid grid-cols-1 gap-2">
                      <div
                        v-if="doctorObject.status == 'invited_doctor'"
                        class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center"
                        style="margin-left: 0px !important;margin-right: auto !important;"
                      >
                        invited
                      </div>
                      <div
                        v-else-if="doctorObject.status == 'invited'"
                        class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center"
                        style="margin-left: 0px !important;margin-right: auto !important;"
                      >
                        invited
                      </div>
                      <div class="text-lg self-center">
                        {{doctorObject.doctor.first_name}} {{ doctorObject.doctor.last_name }}
                      </div>
                      <div class="flex-auto text-gray-400 text-sm block">
                        {{doctorObject.doctor.email}}
                      </div>
                    </div>
                    <div class="flex w-full"></div>
                    <div class="flex w-full">
                      <!--<button
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                      >
                        Chat
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Chatbox.svg"
                        />
                      </button>-->
                      <button
                        v-if="doctorObject.status == 'active'"
                        @click="deleteContact(doctorObject.doctor.email)"
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                      >
                        Delete
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Trash.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, onMounted } from "vue";

  import { computed } from "@vue/runtime-core";
  import base from "../../services/api";

  import { useStore } from "vuex";

  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  import BaseInput from "@/assets/Form/BaseInput.vue";
  import ConfirmationModal from "@/assets/ConfirmationModal.vue";

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  const doctorsSection = ref(false);

  const inviteDoctorModal = ref(false);

  const doctorEmail = ref();

  const isValidEmail = ref(false);

  const isValidEmailFormat = (email) => {
    // Regular expression for checking email format
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Get auth token for bearer
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const emit = defineEmits(["refreshScreen"]);

  const refreshDashboard = async (value) => {
    emit("refreshScreen", value);
  };

  let doctorList = ref([]);

  const getDoctors = async () => {
    base
      .get("/v2/user/list-doctors/",
        { headers }
      ).then((response) => {

        if (response.data?.status === "success") {
          doctorList.value = response.data.data;
        }
      })
      .catch(() => {
        toast('Unable retrieve doctors!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const deleteContact = async (user_email) => {
    base
      .post("/v2/user/delete-contact/", {
          email: user_email
        }, { headers }
      ).then((response) => {
        if (response.data?.status === "success") {
          toast('Success, Please Check email!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          refreshDashboard('true');
        }
      })
      .catch(() => {
        toast('Unable to Delete Contact!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const inviteDoctor = async () => {
    if(doctorEmail.value == '' || doctorEmail.value === undefined){
      toast('Please provide an email address!', {
        type: 'error',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      isValidEmail.value = isValidEmailFormat(doctorEmail.value);
    }

    if(isValidEmail.value == true){
      base
        .post("/v2/user/invite-doctor/", {
          email: doctorEmail.value },
          { headers }
        ).then((response) => {
          if (response.data?.status === "success") {

            toast('Doctor Invite Sucessfull!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            refreshDashboard('true');
            doctorEmail.value = "";
            inviteDoctorModal.value = false;
          } else if(response.data?.message == "Invited user to get the app first") {
            toast('Invited User to Get The App First!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            refreshDashboard('true');
            doctorEmail.value = "";
            inviteDoctorModal.value = false;
          } else {
            toast(response.data?.message, {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          toast('Unable to invite doctor!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    } else {
      toast('Please provide a valid email address!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  onMounted(() => {
    getDoctors();
  });
</script>
