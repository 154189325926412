<template>
  <NavComponent></NavComponent>
  <div class="grid grid-cols-5 h-screen">
    <!-- div left -->
    <div class="relative col-span-2" id="hero-image">
      <img
        class="h-full object-cover hero-image"
        src="../assets/img/assic-learning-slide.jpg"
        alt="Assic Heart monitor"
      />
    </div>

    <!-- div right -->
    <div class="flex w-full h-full justify-center bg-gray-100 col-span-3 pt-24" id="reset-password">
      <div class="self-center w-5/5 lg:w-3/5 xl:w-3/5">
        <div class="text-center">
          <img
            class="mx-auto w-20"
            src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/performance-fingerprint-app.png"
            alt="Assic Logo"
          />
          <h1 class="text-sm lg:text-lg xl:text-lg font-bold text-secondary-500">PERFORMANCE FINGERPRINT</h1>
        </div>
        <div class="text-center">
          <h1 class="text-sm lg:text-lg xl:text-lg font-bold text-primary-500">
            PASSWORD RESET
          </h1>
        </div>

        <div class="grid gap-y-6 py-5">
          <hr
            class="col-span-4 self-center border-t-2 border-grey-200 mx-8 mb-3"
          />
        </div>

        <Form as="div" v-slot="{ handleSubmit }" ref="resetForm" class="mx-8">
          <div class="bg-[#F8F8F8] shadow-md rounded p-5">
            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.password"
                :rules="passwordRules"
                refKey="form.user.password"
                required
                autocomplete="new-password"
                noDetails
                label="New Password"
                :type="passwordVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="passwordVisible = !passwordVisible"
                    type="button"
                  >
                    <EyeIcon v-if="passwordVisible" class="w-6 h6" />
                    <EyeSlashIcon v-else class="w-6 h6" />
                  </button>
                </template>
              </base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.confirmPassword"
                :rules="passwordRules2"
                refKey="form.user.confirmPassword"
                required
                autocomplete="new-password"
                noDetails
                label="Confirm Password"
                :type="passwordConfirmVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="
                      passwordConfirmVisible = !passwordConfirmVisible
                    "
                    type="button"
                  >
                    <EyeIcon v-if="passwordConfirmVisible" class="w-6 h6" />
                    <EyeSlashIcon v-else class="w-6 h6" />
                  </button>
                </template>
              </base-input>
              <div
                class="error text-xs text-secondary-700"
                v-if="errors.password"
              >
                {{ errors.password }}
              </div>
            </div>
          </div>

          <div class="grid gap-y-6 mt-6">
            <button
              class="primaryBtn w-40 mx-auto disabled:bg-slate-300 disabled:btn-active text-white"
              :disabled="!newPassword"
              @click="handleSubmit(resetPassword())"
            >
              RESET PASSWORD
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, watch, inject } from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { string } from "yup";

import NavComponent from "../components/NavComponent.vue";
import BaseInput from "../../src/assets/Form/BaseInput.vue";
import { Form } from "vee-validate";
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/solid";

let initialForm = {
  user: {},
};

const form = reactive(initialForm);
const resetForm = ref(null);
const API = inject("axios");
const router = useRouter();
const route = useRoute();

const errors = ref([]);
const passwordVisible = ref(false);
const passwordConfirmVisible = ref(false);

const newPassword = ref(false);

const requiredRules = string().required("This field is required");

const passwordRules = requiredRules.min(
  6,
  "Password should be at least 6 characters"
);

const passwordRules2 = requiredRules.test(
  "password",
  "Passwords do not match",
  (value) => {
    return value === form.user.password;
  }
);

const resetPassword = async () => {
  const isValid = await resetForm.value.validate();
  if (isValid.valid) {
    API.post("v2/user/change-user-password/", {
      password: form.user.password,
      token: route.query.token,
    })
      .then((res) => {
        if (res.data?.status === "success") {
          toast('Password Successfully Reset!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setTimeout(() => {
            router.push({ path: "/" });
          }, 3000);
        }
      })
      .catch(() => {
        toast('Unable To Reset Password!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }
};

watch(form, () => {
  if (
    form.user.confirmPassword &&
    form.user.confirmPassword === form.user.password
  ) {
    newPassword.value = true;
  } else {
    newPassword.value = false;
  }
});
</script>

<style scoped>
  #hero-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  @media only screen and (max-width: 1026px) {
    #hero-image {
        display: none;
    }

    #reset-password{
      grid-column: span 5 / span 3!important;
    }
}
</style>
