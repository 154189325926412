<template>
    <div class="container mx-auto lg:px-5 xl:px-20">
      <!-- HEADER -->
      <div class="headerDiv mb-5">
        <base-card class="p-0">
          <img src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/calling.png" alt class="rounded w-32 h-32 mx-auto" />
        </base-card>
        <base-card class="col-span-3 flex bg-white">
          <h1 class="headingOne self-center text-paragraph-500 font-normal">
            My
            <span class="font-bold">Assic Calling</span>
          </h1>
        </base-card>
        <base-card class="grid grid-cols-1 col-span-2">
          <div class="grid grid-cols-1 content-center gap-2 text-xs self-center">
            <button
              @click="joinMeeting()"
              class="primaryBtn self-center px-0 uppercase text-xs"
            >
              Join a Meeting
            </button>
            <a
              v-if="user.is_doctor != true"
              href="https://www.assic-health.com/health-professionals/product-category/health-professional/"
              target="_blank"
              class="secondaryBtn self-center px-0 uppercase text-xs"
            >
              Book a Professional
            </a>
            <button
              v-if="user.is_doctor == true"
              @click="invitePatientModal = true"
              class="secondaryBtn self-center px-0 uppercase text-xs"
            >
              Invite Patient
            </button>
          </div>
        </base-card>
      </div>

    <!-- Invite Patient -->
    <ConfirmationModal v-if="invitePatientModal === true">
      <template v-slot:header>Invite Patient</template>
      <template v-slot:content>
        <div class="form-control w-full mb-3">
          <base-input
            v-model="patientEmail"
            refKey="patientEmail"
            label="Patient's Email"
            required
          ></base-input>
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="secondaryBtn w-40 mx-5"
          @click="invitePatientModal = false"
        >Close</button>
        <button
          class="primaryBtn w-40"
          @click="invitePatient();"
        >
          Invite
        </button>
      </template>
      <template v-slot:background>
        <div
          v-if="invitePatientModal === true"
          class="opacity-75 fixed inset-0 z-40 bg-black"
        ></div>
      </template>
    </ConfirmationModal>

      <div class="grid grid-cols-1 gap-10 bg-white p-10 rounded mb-6 shadow-xl">
        <div>
          <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5 mb-5">
            <MyInvites :key="inviteKey" @refresh-screen="refreshScreen"></MyInvites>
          </div>
          <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5 mb-5">
            <MeetingsBooked></MeetingsBooked>
          </div>
          <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5 mb-5" v-if="user.is_doctor == true">
            <MyPatients :key="patientKey" @refresh-screen="refreshScreen"></MyPatients>
          </div>
          <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5 mb-5" v-if="user.is_doctor != true">
            <MyDoctors :key="doctorKey" @refresh-screen="refreshScreen"></MyDoctors>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
  import BaseCard from "../../assets/BaseCard.vue";
  import MeetingsBooked from "./MeetingsBooked.vue";
  import MyPatients from "./MyPatients.vue";
  import MyInvites from "./MyInvites.vue";
  import MyDoctors from "./MyDoctors.vue";

  import BaseInput from "@/assets/Form/BaseInput.vue";
  import ConfirmationModal from "@/assets/ConfirmationModal.vue";

  import { ref } from "vue";
  import { computed } from "@vue/runtime-core";
  import base from "../../services/api";

  import { useStore } from "vuex";
  import { useRouter } from "vue-router";

  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  const router = useRouter();
  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  const invitePatientModal = ref(false);

  const patientEmail = ref();

  const isValidEmail = ref(false);

  const isValidEmailFormat = (email) => {
    // Regular expression for checking email format
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const joinMeeting = async () => {
    router.push({ path: "/join-meeting" });
  }

    // Get auth token for bearer
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const inviteKey = ref(0);
  const patientKey = ref(0);
  const doctorKey = ref(0);

  // Refreshes the entirey of all tabs for the charts componenet
  const forceRerender = () => {
    inviteKey.value += 1;
    patientKey.value += 1;
    doctorKey.value += 1;
  }

  const refreshScreen = async (value) => {
    if(value == 'true'){
      forceRerender();
    }
  };

  const invitePatient = async () => {

    if(patientEmail.value == '' || patientEmail.value === undefined){
      toast('Please provide an email address!', {
        type: 'error',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      isValidEmail.value = isValidEmailFormat(patientEmail.value);
    }

    if(isValidEmail.value == true){
      base
        .post("/v2/user/invite-patient/", {
          email: patientEmail.value },
          { headers }
        ).then((response) => {
          if (response.data?.status === "success") {
            toast('Patient Invite Sucessfull!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            forceRerender();
            patientEmail.value = "";
            invitePatientModal.value = false;
          } else if(response.data?.message == "Invited user to get the app first") {
            toast('Invited User to Get The App First!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            forceRerender();
            patientEmail.value = "";
            invitePatientModal.value = false;
          } else {
            toast(response.data?.message, {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          toast('Unable to invite patient!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      } else {
        toast('Please provide a valid email address!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
  };
</script>
