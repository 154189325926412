<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-4 gap-5">
      <div class="form-control w-full mb-1">
        <h1 class="headingOne text-2xl font-bold text-[#161922] mb-3">My Patients ({{ patientList.length }})</h1>
      </div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1">
        <button
          v-if="patientsSection == false"
          @click="patientsSection = true"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
          :disabled="!patientList.length"
        >
          Display
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/More.svg"
          />
        </button>
        <button
          v-else-if="patientsSection == true"
          @click="patientsSection = false;"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
        >
          Hide
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/Hide.svg"
          />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5" v-if="patientsSection == true">
      <div class="w-full">
        <div class="bg-white rounded p-5 mb-6 shadow-xl">
          <!-- YOUR ROUTINES -->
          <div class="p-5 mx-auto">
            <div class="bg-white rounded grid grid-cols-1 gap-10">
              <div
                  v-for="patientObject in patientList"
                  :key="patientObject.patient.id"
                  :patientObject="patientObject"
              >
                <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                  <div class="grid grid-cols-3 gap-2">
                    <div class="grid grid-cols-1 gap-2">
                      <div
                        v-if="patientObject.status == 'invited'"
                        class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center"
                        style="margin-left: 0px !important;margin-right: auto !important;"
                      >
                        invited
                      </div>
                      <div
                        v-else-if="patientObject.status == 'invited_doctor'"
                        class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center"
                        style="margin-left: 0px !important;margin-right: auto !important;"
                      >
                        invited
                      </div>
                      <div class="text-lg self-center">
                        {{patientObject.patient.first_name}} {{ patientObject.patient.last_name }}
                      </div>
                      <div class="flex-auto text-gray-400 text-sm block">
                        {{patientObject.patient.email}}
                      </div>
                    </div>
                    <div class="flex w-full"></div>
                    <div class="flex w-full">
                      <!--<button
                        v-if="patientObject.status != 'invited'"
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                      >
                        Chat
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Chatbox.svg"
                        />
                      </button>-->
                      <button
                        v-if="patientObject.status == 'active'"
                        @click="deleteContact(patientObject.patient.email)"
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                      >
                        Delete
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Trash.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, onMounted } from "vue";

  import { computed } from "@vue/runtime-core";
  import base from "../../services/api";

  import { useStore } from "vuex";

  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  // Get auth token for bearer
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const emit = defineEmits(["refreshScreen"]);

  const refreshDashboard = async (value) => {
    emit("refreshScreen", value);
  };

  let patientList = ref([]);
  const patientsSection = ref(false);

  const getPatients = async () => {
    base
      .get("/v2/user/list-patients/",
        { headers }
      ).then((response) => {
        if (response.data?.status === "success") {
          patientList.value = response.data.data;
        }
      })
      .catch(() => {
        toast('Unable retrieve patients!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const deleteContact = async (user_email) => {
    base
      .post("/v2/user/delete-contact/", {
          email: user_email },
          { headers }
      ).then((response) => {
        if (response.data?.status === "success") {
          toast('Contact Deleted!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          refreshDashboard('true');
        }
      })
      .catch(() => {
        toast('Unable to Delete Contact!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onMounted(() => {
    getPatients();
  });

</script>
