<template>
  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          label="From"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          label="To"
          :minDate="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-6 gap-2 mb-4">
    <button class="btnTab" @click="getExerciseTime(); determineCurrentTab('ExerciseTime')">
      Exercise Time Data
    </button>
    <button class="btnTab" @click="getTrainingTime(); determineCurrentTab('TrainingTime')">
      Training Time Data
    </button>
    <button class="btnTab" @click="getGymTime(); determineCurrentTab('GymTime')">Gym Time Data</button>
    <button class="btnTab" @click="getMatchTime(); determineCurrentTab('MatchTime')">Match Time Data</button>
    <button class="btnTab" @click="getStepCount(); determineCurrentTab('StepCount')">Step Count Data</button>
    <button class="btnTab" @click="getOvers(); determineCurrentTab('Overs')">Overs Data</button>
    <button class="btnTab" @click="getSets(); determineCurrentTab('Sets')">Sets Data</button>
    <button class="btnTab" @click="getLaps(); determineCurrentTab('Laps')">Laps Data</button>
    <button class="btnTab" @click="getDistance(); determineCurrentTab('Distance')">Distance Data</button>
  </div>

  <!-- Chart -->
  <div
    class="chart bg-gray-300 relative rounded-lg rounded-t-none p-10 mb-5"
    id="chartWrapper"
  >
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import moment from "moment";

  import { Chart, registerables } from "chart.js";

  Chart.register(...registerables);

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";


  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };

  const parseInteger = (value) => {
    return parseInt(value, 10); // Consistent integer parsing
  };

  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  const dataArr = ref([]);
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);
  const dataSeriesList2 = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const exerciseTimeTab = ref(true);
  const trainingTimeTab = ref(false);
  const gymTimeTab = ref(false);
  const matchTimeTab = ref(false);
  const stepCountTab = ref(false);
  const oversDataTab = ref(false);
  const setsDataTab = ref(false);
  const lapsDataTab = ref(false);
  const distanceDataTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const generateDataSet = async (label,
      data,
      backgroundColor = "#F04248",
      borderColor = "#F04248") => {
        graphData.value = {
            label: label,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: data,
            fill: false
          }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
    myLine.data.datasets = datasets.value;
    myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

    /*
      This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
      when adding data to customizing dates
    */

    if(tabType == "ExerciseTime"){
      exerciseTimeTab.value = true;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "TrainingTime"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = true;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "GymTime"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = true;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "MatchTime"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = true;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "StepCount"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = true;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "Overs"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = true;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "Sets"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = true;
      lapsDataTab.value = false;
      distanceDataTab.value = false;
    }
    if(tabType == "Laps"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = true;
      distanceDataTab.value = false;
    }
    if(tabType == "Distance"){
      exerciseTimeTab.value = false;
      trainingTimeTab.value = false;
      gymTimeTab.value = false;
      matchTimeTab.value = false;
      stepCountTab.value = false;
      oversDataTab.value = false;
      setsDataTab.value = false;
      lapsDataTab.value = false;
      distanceDataTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(exerciseTimeTab.value == true){
      getExerciseTime();
    }
    if(trainingTimeTab.value == true){
      getTrainingTime();
    }
    if(gymTimeTab.value == true){
      getGymTime();
    }
    if(matchTimeTab.value == true){
      getMatchTime();
    }
    if(stepCountTab.value == true){
      getStepCount();
    }
    if(oversDataTab.value == true){
      getOvers();
    }
    if(setsDataTab.value == true){
      getSets();
    }
    if(lapsDataTab.value == true){
      getLaps();
    }
    if(distanceDataTab.value == true){
      getDistance();
    }
  };

  const getExerciseTime = async () => {
    base
        .post(`/profiles/special/get/1/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on); // Properly formatted date
              const exercise_time = parseInteger(d.exercise_time); // Parse to integer

              categoriesList.value.push(date);
              dataSeriesList.value.push(exercise_time);
            });
            generateDataSet("Exercise Time (min)", dataSeriesList);
            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getExerciseTime Error");
        });
  };

  const getTrainingTime = async () => {

      base
        .post(`/profiles/special/get/2/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { training_time } = d;

              dataArr.value.push([date, parseInt(training_time)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(training_time));
            });

            generateDataSet("Training Time (min)", dataSeriesList);

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getTrainingTime Error");
        });
  };

  const getGymTime = async () => {
    base
        .post(`/profiles/special/get/8/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { gym_time } = d;

              dataArr.value.push([date, parseInt(gym_time)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(gym_time));
            });

            generateDataSet("Gym Time (min)", dataSeriesList);

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getGymTime Error");
        });
  };

  const getMatchTime = async () => {
    base
        .post(`/profiles/special/get/9/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { match_time } = d;

              // this.dataArr.value.push([date, parseInt(match_time)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(match_time));
            });

            generateDataSet("Match Time (min)", dataSeriesList);
            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getMatchTime Error");
        });
  };

  const getStepCount = async () => {
    base
        .post(`/profiles/special/get/10/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { step_count } = d;

              // this.dataArr.value.push([date, parseInt(step_count)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(step_count));
            });

            generateDataSet("Step Count", dataSeriesList);

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getStepCount Error");
        });
  };

  const getOvers = async () => {
    base
        .post(`/profiles/special/get/184/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { overs } = d;

              // this.dataArr.value.push([date, parseInt(overs)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(overs));
            });

            generateDataSet("Overs", dataSeriesList);

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("oversList Error");
        });
  };

  const getSets = async () => {
    base
        .post(`/profiles/special/get/185/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { sets } = d;

              dataArr.value.push([date, parseInt(sets)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(sets));
            });

            generateDataSet("Sets", dataSeriesList);

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getSets Error");
        });
  };

  const getLaps = async () => {
    base
        .post(`/profiles/special/get/186/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.details;
            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { laps } = d;
              dataArr.value.push([date, parseInt(laps)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(laps));
            });
            generateDataSet("Laps", dataSeriesList);
            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getSets Error");
        });
  };

  const getDistance = async () => {
    base
        .post(`/profiles/special/get-distance-time/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;
            categoriesList.value = [];
            dataSeriesList.value = [];
            dataSeriesList2.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { distance, time } = d;

              dataArr.value.push([date, parseInt(distance)]);
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseFloat(distance));
              dataSeriesList2.value.push(parseFloat(time));
            });

            generateDataSet("Distance (km)", dataSeriesList);

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
                "Time (min)",
                dataSeriesList2,
                "#058EF0",
                "#058EF0"
              )
            datasets.value.push(
              graphData.value
            );

            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getSets Error");
        });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      getExerciseTime();
    });
  });

</script>


<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}

.chart {
  background-color: #efefef;
}
</style>
