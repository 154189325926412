<template>
  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          label="From"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          label="To"
          :minDate="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-4 gap-2 mb-4">
    <button class="btnTab" @click="getRestingHR(); determineCurrentTab('RestingHR')">
      Resting Heart Rate
      Data
    </button>
    <button class="btnTab" @click="getBloodPressure(); determineCurrentTab('BloodPressure')">
      Blood Pressure
      Data
    </button>
    <button class="btnTab" @click="getTemperature(); determineCurrentTab('Temperature')">
      Temperature
      Data
    </button>
    <button class="btnTab" @click="getIOBalance(); determineCurrentTab('IOBalance')">
      Input/Output Balance
      Data
    </button>
    <button class="btnTab" @click="getGlucose(); determineCurrentTab('Glucose')">
      Glucose
      Data
    </button>
    <button class="btnTab" @click="getRespiratoryRate(); determineCurrentTab('RespiratoryRate')">
      Respiratory Rate
      Data
    </button>
    <button class="btnTab" @click="getMassChange(); determineCurrentTab('MassChange')">
      Mass Change
      Data
    </button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5" id="chartWrapper">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import moment from "moment";

  import { Chart, registerables } from "chart.js";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);


  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };


  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);
  const dataSeriesList2 = ref(null);
  const dataSeriesList3 = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const restingHRTab = ref(true);
  const bloodPressureTab = ref(false);
  const temperatureTab = ref(false);
  const iOBalanceTab = ref(false);
  const massChangeTab = ref(false);
  const respiratoryRateTab = ref(false);
  const glucoseTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const generateDataSet = async (label,
      data,
      backgroundColor = "#F04248",
      borderColor = "#F04248") => {
        graphData.value = {
            label: label,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: data,
            fill: false
          }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
    myLine.data.datasets = datasets.value;
    myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

    /*
      This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
      when adding data to customizing dates
    */

    if(tabType == "RestingHR"){
      restingHRTab.value = true;
      bloodPressureTab.value = false;
      temperatureTab.value = false;
      iOBalanceTab.value = false;
      massChangeTab.value = false;
      respiratoryRateTab.value = false;
      glucoseTab.value = false;
    }
    if(tabType == "BloodPressure"){
      restingHRTab.value = false;
      bloodPressureTab.value = true;
      temperatureTab.value = false;
      iOBalanceTab.value = false;
      massChangeTab.value = false;
      respiratoryRateTab.value = false;
      glucoseTab.value = false;
    }
    if(tabType == "Temperature"){
      restingHRTab.value = false;
      bloodPressureTab.value = false;
      temperatureTab.value = true;
      iOBalanceTab.value = false;
      massChangeTab.value = false;
      respiratoryRateTab.value = false;
      glucoseTab.value = false;
    }
    if(tabType == "IOBalance"){
      restingHRTab.value = false;
      bloodPressureTab.value = false;
      temperatureTab.value = false;
      iOBalanceTab.value = true;
      massChangeTab.value = false;
      respiratoryRateTab.value = false;
      glucoseTab.value = false;
    }
    if(tabType == "MassChange"){
      restingHRTab.value = false;
      bloodPressureTab.value = false;
      temperatureTab.value = false;
      iOBalanceTab.value = false;
      massChangeTab.value = true;
      respiratoryRateTab.value = false;
      glucoseTab.value = false;
    }
    if(tabType == "RespiratoryRate"){
      restingHRTab.value = false;
      bloodPressureTab.value = false;
      temperatureTab.value = false;
      iOBalanceTab.value = false;
      massChangeTab.value = false;
      respiratoryRateTab.value = true;
      glucoseTab.value = false;
    }
    if(tabType == "Glucose"){
      restingHRTab.value = false;
      bloodPressureTab.value = false;
      temperatureTab.value = false;
      iOBalanceTab.value = false;
      massChangeTab.value = false;
      respiratoryRateTab.value = false;
      glucoseTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(restingHRTab.value == true){
      getRestingHR();
    }
    if(bloodPressureTab.value == true){
      getBloodPressure();
    }
    if(temperatureTab.value == true){
      getTemperature();
    }
    if(iOBalanceTab.value == true){
      getIOBalance();
    }
    if(massChangeTab.value == true){
      getMassChange();
    }
    if(respiratoryRateTab.value == true){
      getRespiratoryRate();
    }

    if(glucoseTab.value == true){
      getGlucose();
    }
  };

  const getRestingHR = async () => {
    base
      .post(`/profiles/special/resting-hr-date-range/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;
          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { rested_hr } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(rested_hr));
          });

          generateDataSet(
            "Resting Heart Rate (bpm)",
            dataSeriesList
          );
          datasets.value.push(
            graphData.value
          );
          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getRestingHR Error");
      });
  };

  const getBloodPressure = async () => {
    base
        .post(`/profiles/special/bp-get-custom-date-range/`, {
          date_from: fromDate.value,
          date_to: toDate.value,
        })
        .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;
            categoriesList.value = [];
            dataSeriesList.value = [];
            dataSeriesList2.value = [];
            datasets.value = [];

            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);

              const { systolic_value, diastolic_value } = d;

              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(systolic_value));
              dataSeriesList2.value.push(parseInt(diastolic_value));
            });

            generateDataSet("Systolic Value (mmHg)", dataSeriesList);

            datasets.value.push(
              graphData.value
            );

            generateDataSet(
              "Diastolic Value (mmHg)",
              dataSeriesList2,
              "#058EF0",
              "#058EF0"
            );

            datasets.value.push(
              graphData.value
            );
            updateChart();
          } else {
            toast('No data found!', {
              type: 'warning',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          console.error("getBloodPressure Error");
        });
  };

  const getTemperature = async () => {
    base
      .post(`/profiles/special/get/189/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;
          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { temperature } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(temperature));
          });

          generateDataSet("Temperature (°C)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );
          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getTemperature Error");
      });
  };

  const getIOBalance = async () => {
    base
      .post(`/profiles/special/get-input-output/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;
          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { input, output, additional_drainage } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(input));
            dataSeriesList2.value.push(parseFloat(output));
            dataSeriesList3.value.push(parseFloat(additional_drainage));
          });

          generateDataSet("Input (l)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Output (l)",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Additional Drainage (l)",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getIOBalance Error");
      });
  };

  const getGlucose = async () => {
    base
      .post(`/profiles/special/get/191/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;
          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { glucose } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(glucose));
          });

          generateDataSet("Glucose (mmol/L)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getGlucose Error");
      });
  };

  const getRespiratoryRate = async () => {
    base
      .post(`/profiles/special/get/192/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;
          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { respiratory_rate } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(respiratory_rate));
          });

          generateDataSet(
              "Respiratory Rate (bpm)",
              dataSeriesList
            );

          datasets.value.push(
            graphData.value
          );
          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getRespiratoryRate Error");
      });
  };

  const getMassChange = async () => {
    base
      .post(`/profiles/special/get-mass-change/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;
          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { mass_before, mass_after } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseFloat(mass_before));
            dataSeriesList2.value.push(parseFloat(mass_after));
          });

          generateDataSet("Mass Before (kg)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Mass After (kg)",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        } else {
          toast('No data found!', {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        console.error("getMassChange Error");
      });
  };


  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      getRestingHR();
    });
  });

</script>


<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}

.chart {
  background-color: #efefef;
}
</style>

