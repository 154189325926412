<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-4 gap-5">
      <div class="form-control w-full mb-1">
        <h1 class="headingOne text-2xl font-bold text-[#161922] mb-3">Meetings Booked ({{ listMeetingsBooked.length }})</h1>
      </div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1">
        <button
          v-if="meetingsSection == false"
          @click="meetingsSection = true"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
          :disabled="!listMeetingsBooked.length"
        >
          Display
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/More.svg"
          />
        </button>
        <button
          v-else-if="meetingsSection == true"
          @click="meetingsSection = false;"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
        >
          Hide
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/Hide.svg"
          />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5" v-if="meetingsSection == true">
      <div class="w-full">
        <div class="bg-white rounded p-5 mb-6 shadow-xl">
          <!-- YOUR ROUTINES -->
          <div class="p-5 mx-auto">
            <div class="bg-white rounded grid grid-cols-1 gap-10">
              <div
                  v-for="meetingObject in listMeetingsBooked"
                  :key="meetingObject.id"
                  :meetingObject="meetingObject"
              >
                <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                  <div class="grid grid-cols-2 gap-2">
                    <div class="grid grid-cols-1 gap-2">
                      <div class="flex-auto text-[#0b7db6] text-sm block">
                        {{meetingObject.meeting_date_time}}
                      </div>
                      <div class="text-lg self-center">
                        {{meetingObject.meeting_name}}
                      </div>
                      <div class="flex-auto text-gray-400 text-sm block">
                        Meeting ID: {{meetingObject.meeting_id}}
                      </div>
                    </div>
                    <div class="flex w-full">
                      <button
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:26%"
                        @click="joinMeeting(meetingObject.meeting_id)"
                      >
                        Join
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/JoinMeeting.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { ref, onMounted } from "vue";
  import { computed } from "@vue/runtime-core";
  import base from "../../services/api";

  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  const router = useRouter();
  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const meetingsSection = ref(false);
  let listMeetingsBooked = ref([]);

  const getMeetings = async () => {
    base
      .post("/meetings/list-meetings/", {
        email: user.value.email },
        { headers }
      ).then((response) => {
        if (response.data?.status === "success") {
          listMeetingsBooked.value = JSON.parse(response.data?.data);
        }
      })
      .catch(() => {
        toast('Unable to retrieve booked meetings!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    });
  };

  const joinMeeting = async (meeting_id) => {
    router.push({ path: "/join-meeting", query: { meetingid: `${meeting_id}` } });
  };

  onMounted(() => {
    getMeetings();
  });

</script>
