<template>
  <NavComponent></NavComponent>
  <main>
    <section class="relative block" style="height: 300px;">
      <div class="absolute top-0 w-full h-full bg-center bg-cover bg-primary-500">
      </div>
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
        style="height: 70px;"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon class="text-gray-100 fill-current" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </section>

    <section class="relative py-16 bg-[#F8F8F8] shadow-md">
      <div class="container mx-auto lg:px-5 xl:px-20">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
        >
          <div class="px-5">
            <div class="grid lg:grid-cols-3 pb-10">
              <div class="text-center my-8 lg:py-10 lg:col-span-1">
                <div class="flex w-full justify-center mb-10">
                  <img
                    alt="..."
                    src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/performance-fingerprint-app.png"
                    class="shadow-xl rounded-full h-auto align-middle border-none"
                    style="max-width: 150px;"
                  />
                </div>
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-gray-900"
                >{{ user.first_name }} {{ user.last_name }}</h3>
                <div class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                  <i class="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>
                  {{ user.email }}
                </div>
                <div class="mb-2 text-gray-700 mt-5">
                  <i class="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                  Birthday:
                  {{ user.date_of_birth }}
                </div>
                <div class="mb-2 text-gray-700 mt-8">
                  <i class="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                  <!-- <button class="primaryBtn" @click="viewProfile">view pf profiles</button> -->
                </div>
              </div>
              <div
                class="mt-8 py-10 sm:border-t lg:border-t-0 lg:col-span-2 border-gray-300"
              >
                <div class="flex flex-wrap justify-center">
                    <Form v-slot="{ handleSubmit }"  ref="updateForm" class="mx-8">
                      <div class="bg-[#F8F8F8] shadow-md mx-auto rounded p-5">
                        <div class="grid grid-cols-2 gap-5">
                          <div class="form-control w-full mb-1">
                            <base-input
                              v-model="form.updatedUser.first_name"
                              refKey="form.updatedUser.first_name"
                              :rules="requiredRules"
                              required
                              label="First Name"
                            ></base-input>
                          </div>
                          <div class="form-control w-full mb-1">
                            <base-input
                              v-model="form.updatedUser.last_name"
                              refKey="form.updatedUser.last_name"
                              :rules="requiredRules"
                              required
                              label="Last Name"
                            ></base-input>
                          </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5">
                          <div class="form-control w-full mb-1">
                            <base-select
                              v-model="form.updatedUser.gender"
                              refKey="form.updatedUser.gender"
                              label="Gender"
                              required
                              :items="[{ text: 'Male' }, { text: 'Female' }]"
                              item-text="text"
                              item-value="text"
                              :excludeOption="false"
                            ></base-select>
                          </div>
                          <div class="form-control w-full mb-1">
                            <base-select
                              v-model="form.updatedUser.fitness_type"
                              refKey="form.updatedUser.fitness_type"
                              label="Fitness Type"
                              :rules="requiredRules"
                              :items="[{ text: 'Sedentary', value: 'sedentary' }, { text: 'Athlete', value: 'athlete' }]"
                              item-text="text"
                              item-value="value"
                              :excludeOption="false"
                            ></base-select>
                          </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5">
                          <div class="form-control w-full mb-1">
                            <base-input
                              v-model="form.updatedUser.height"
                              refKey="form.updatedUser.height"
                              :rules="requiredRules"
                              label="Height"
                              required
                              v-mask="'#.##'"
                            ></base-input>
                          </div>
                          <div class="form-control w-full mb-1">
                            <base-input
                              v-model="form.updatedUser.mass"
                              refKey="form.updatedUser.mass"
                              :rules="requiredRules"
                              label="Mass"
                              required
                              type="number"
                            ></base-input>
                          </div>
                        </div>
                        <div class="form-control w-full mb-1">
                          <base-date-picker
                            v-model="form.updatedUser.date_of_birth"
                            refKey="form.updatedUser.date_of_birth"
                            noDetails
                            label="Date of Birth"
                            :maxDate="new Date()"
                            required
                          ></base-date-picker>
                        </div>

                        <div class="grid grid-cols-3 gap-5">
                          <div class="col-span-2 form-control w-full">
                            <label class="label">
                              <span
                                class="inputLabel font-bold -mb-1"
                              >Enter your password to update your info</span>
                            </label>
                            <base-input
                              v-model="form.updatedUser.password"
                              refKey="form.updatedUser.password"
                              autocomplete="new-password"
                              :rules="passwordRules"
                              noDetails
                              required
                              label="Password"
                              :type="passwordVisible ? 'text' : 'password'"
                            >
                              <template v-slot:append>
                                <button
                                  class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                                  @click.prevent="passwordVisible = !passwordVisible"
                                  type="button"
                                >
                                  <EyeIcon v-if="passwordVisible" class="w-6 h6" />
                                  <EyeSlashIcon v-else class="w-6 h6" />
                                </button>
                              </template>
                            </base-input>
                          </div>
                          <div class="flex w-full">
                            <button class="primaryBtn w-full self-center mt-6" @click.prevent="handleSubmit(updateUser())">UPDATE</button>
                          </div>
                        </div>
                        <div class="grid grid-cols-3 gap-5">
                          <div class="flex w-full col-span-3">
                            <button class="primaryBtn w-full self-center mt-6" @click.prevent="generatePin()">GENERATE SECURITY PIN</button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  </main>
  <FooterComponent></FooterComponent>
</template>

<script setup>
  import base from "../services/api";
  import { useStore } from "vuex";
  import { computed, watch } from "vue";
  import { ref, reactive, onMounted } from "vue";
  import { Form } from "vee-validate";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import { string } from "yup";

  import BaseInput from "../../src/assets/Form/BaseInput.vue";
  import BaseDatePicker from "../../src/assets/Form/BaseDatePicker.vue";
  import BaseSelect from "@/assets/Form/BaseSelect.vue";
  import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/24/solid';
  import FooterComponent from "../components/FooterComponent.vue";
  import NavComponent from "../components/NavComponent.vue";

  // get user
  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]) ?? {};

  const passwordVisible = ref(false);

  let initialForm = {
    updatedUser: {},
  };

  const form = reactive(initialForm);
  const updateForm = ref(null);

  const requiredRules = string().required("This field is required");

  const passwordRules = string().required().min(6, "Password should be at least 6 characters");

  watch(
    user,
    (v) => {
      form.updatedUser = v;
      form.updatedUser.password = "";
    },
    { immediate: true }
  );

  const updateUser = async () => {

    const isValid = await updateForm.value.validate();

    if (isValid.valid) {
      base
        .post("/user/update-profile/", {
          first_name: form.updatedUser.first_name,
          last_name: form.updatedUser.last_name,
          gender: form.updatedUser.gender,
          height: form.updatedUser.height,
          mass: form.updatedUser.mass,
          date_of_birth: form.updatedUser.date_of_birth,
          fitness_type: form.updatedUser.fitness_type,
          password: form.updatedUser.password }, {
          headers: {
            Authorization: `ASSIC ${user.value.token}`,
          },
        })
        .then((response) => {
          if (response.data?.status === "success") {
            store.dispatch("auth/setUser", form.updatedUser);
            toast('User Info Successfully Updated!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          toast('Unable To Update User!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      } else {
        toast('Please fill in all required fields!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
  }

  const generatePin = async () => {
    base.post("/v2/user/generate-secure-pin/")
        .then((response) => {
          if (response.data?.status === "success") {

            toast('Successfully Generated Pin!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
            toast('Unable Generate Security Pin!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    };

  onMounted(() => {
    viewProfile();
  });

  // profiles

  const viewProfile = async () => {
    base
      .get(
        "/v2/profiles/",
        {
          headers: {
            Authorization: `ASSIC ${user.value.token}`,
          },
        }
      )
      .catch(() => {
        toast('Unable To View Profile!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
</script>
