<template>
  <!-- Invite Patient -->
  <ConfirmationModal v-if="acceptInviteModal === true">
    <template v-slot:header>Invite Patient</template>
    <template v-slot:content>
      <div class="form-control w-full mb-3">
        <p class="mx-auto">By accepting this invite, you are giving consent for the doctor to view your medical data.</p>
      </div>
    </template>
    <template v-slot:footer>
      <button
        class="secondaryBtn w-40 mx-5"
        @click="acceptInviteModal = false"
      >Cancel</button>
      <button
        class="primaryBtn w-40"
        @click="acceptInvite(selectedInviteLink)"
      >
        Accept
      </button>
    </template>
    <template v-slot:background>
      <div
        v-if="acceptInviteModal === true"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>
  <div class="container mx-auto">
    <div class="grid grid-cols-4 gap-5">
      <div class="form-control w-full mb-1">
        <h1 class="headingOne text-2xl font-bold text-[#161922] mb-3">My Invites ({{ inviteList.length }})</h1>
      </div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1"></div>
      <div class="w-full mb-1">
        <button
          v-if="invitesSection == false"
          @click="invitesSection = true"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
          :disabled="!inviteList.length"
        >
          Display
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/More.svg"
          />
        </button>
        <button
          v-else-if="invitesSection == true"
          @click="invitesSection = false;"
          class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
          style="margin-left: auto !important; margin-right: 0 !important;width:100%;"
        >
          Hide
          <img
            class="h-5 w-5 ml-2 secondaryBtnIcon"
            src="../../assets/img/Hide.svg"
          />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5" v-if="invitesSection == true">
      <div class="w-full">
        <div class="bg-white rounded p-5 mb-6 shadow-xl">
          <!-- YOUR ROUTINES -->
          <div class="p-5 mx-auto">
            <div class="bg-white rounded grid grid-cols-1 gap-10">
              <div
                  v-for="inviteObject in inviteList"
                  :key="inviteObject.id"
                  :inviteObject="inviteObject"
              >
                <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                  <div class="grid grid-cols-3 gap-2">
                    <div class="grid grid-cols-1 gap-2" v-if="user.is_doctor == true">
                      <div class="text-lg self-center">
                        {{inviteObject.patient.first_name}} {{ inviteObject.patient.last_name }}
                      </div>
                      <div class="flex-auto text-gray-400 text-sm block">
                        {{inviteObject.patient.email}}
                      </div>
                    </div>
                    <div class="grid grid-cols-1 gap-2" v-else-if="user.is_doctor != true">
                      <div class="text-lg self-center">
                        {{inviteObject.doctor.first_name}} {{ inviteObject.doctor.last_name }}
                      </div>
                      <div class="flex-auto text-gray-400 text-sm block">
                        {{inviteObject.doctor.email}}
                      </div>
                    </div>
                    <div class="flex w-full">

                    </div>
                    <div class="flex w-full">
                      <button
                        class="declineBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                        @click="declineInvite(inviteObject.decline_link)"
                      >
                        Decline
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Cross.svg"
                        />
                      </button>
                      <button
                        v-if="user.is_doctor != true"
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                        @click="confirmInviteAccept(inviteObject.accept_link)"
                      >
                        Accept
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Checkmark.svg"
                        />
                      </button>
                      <button
                        v-else-if="user.is_doctor == true"
                        class="primaryBtn uppercase text-xs mx-1 border-none px-2"
                        style="margin-left: auto!important;margin-right: 0!important;width:40%"
                        @click="acceptInvite(inviteObject.accept_link)"
                      >
                        Accept
                        <img
                          class="h-5 w-5 ml-2"
                          src="../../assets/img/Checkmark.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, onMounted } from "vue";
  import { computed } from "@vue/runtime-core";
  import base from "../../services/api";
  import { useStore } from "vuex";

  import ConfirmationModal from "@/assets/ConfirmationModal.vue";

  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  const invitesSection = ref(false);
  const selectedInviteLink = ref(null);

  // Get auth token for bearer
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const emit = defineEmits(["refreshScreen"]);

  const refreshDashboard = async (value) => {
    emit("refreshScreen", value);
  };

  const acceptInviteModal = ref(false);

  let inviteList = ref([]);

  const getInvites = async () => {
    base
      .get("/v2/user/list-invites/", {
        email: user.value.email },
        { headers }
      ).then((response) => {
        if (response.data?.status === "success") {
          inviteList.value = response.data.data
        }
      })
      .catch(() => {
        toast('Unable to retrieve invites!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const confirmInviteAccept = async (link) => {
    selectedInviteLink.value = link;
    acceptInviteModal.value = true;
  }

  const acceptInvite = async (accept_link) => {
    base
      .get(accept_link,
        { headers }
      ).then((response) => {
        if (response.data == "Successfully accepted invitation") {
          toast('Successfully Accepted Invitation!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          selectedInviteLink.value = false;
          acceptInviteModal.value = false;
          refreshDashboard('true');
        }
      })
      .catch(() => {
        toast('Unable to accept invite!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const declineInvite = async (decline_link) => {
    base
      .get(decline_link,
        { headers }
      ).then((response) => {
        if (response.data == "Successfully declined invitation") {
          toast('Successfully Declined Invitation!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          refreshDashboard('true');
        }
      })
      .catch(() => {
        toast('Unable to decline invite!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onMounted(() => {
    getInvites();
  });

</script>
