<template>
  <NavComponent></NavComponent>
  <div class="grid grid-cols-5 h-screen">
    <!-- div left -->
    <div class="relative col-span-2" id="hero-image">
      <img
        class="h-full object-cover hero-image"
        src="../assets/img/assic-learning-slide.jpg"
        alt="Assic Heart monitor"
      />
    </div>

    <!-- div right -->
    <div class="flex w-full h-full justify-center bg-gray-100 col-span-3 pt-24" id="reset-password">
      <div class="self-center w-5/5 lg:w-3/5 xl:w-3/5">
        <div class="text-center">
          <img
            class="mx-auto w-20"
            src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/performance-fingerprint-app.png"
            alt="Assic Logo"
          />
          <h1 class="text-sm lg:text-lg xl:text-lg font-bold text-secondary-500">PERFORMANCE FINGERPRINT</h1>
        </div>
        <div class="text-center">
          <h1 class="text-sm lg:text-lg xl:text-lg font-bold text-primary-500">FORGOT PASSWORD</h1>
        </div>

        <div class="grid gap-y-6 py-5">
          <hr class="col-span-4 self-center border-t-2 border-grey-200 mx-8 mb-3" />
        </div>

        <Form
          as="div"
          ref="resetForm"
          class="mx-8"
        >
          <div class="bg-[#F8F8F8] shadow-md rounded p-5">
            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.email"
                refKey="form.user.email"
                :rules="requiredRules"
                required
                label="Email Address"
              ></base-input>
              <div
                class="error text-xs text-secondary-700"
                v-if="errors.email"
              >{{ errors.email }}</div>
            </div>
          </div>

          <div class="grid gap-y-6 mt-6">
            <button
              class="primaryBtn w-40 mx-auto disabled:bg-slate-300 disabled:btn-active text-white"
              :disabled="!isValidEmail"
              @click="sendResetPassword"
            >
              RESET PASSWORD
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref, reactive, watch, inject } from 'vue';
import NavComponent from "../components/NavComponent.vue";
import BaseInput from "../assets/Form/BaseInput.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { Form } from "vee-validate";
import { string } from "yup";

let initialForm = {
  user: {},
};

const form = reactive(initialForm);
const resetForm = ref(null);
const API = inject("axios");

const router = useRouter();

const errors = ref([]);
const requiredRules = string().required("This field is required");

/*
  sendResetPassword function is responsible for sending a Reset Password email to the user
  that his inputted their address.

  Note this email will redirect the user to PasswordResetView
*/

const sendResetPassword = async () => {
  const isValid = await resetForm.value.validate();
  if (isValid.valid) {
    API.post("v2/user/send-password-reset-email/", {
      email: form.user.email,
    })
      .then((res) => {
        if (res.data?.status === "success") {

          toast('Sending Password Request!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setTimeout(() => {
            router.push({ path: "/" });
          }, 3000);

        }
      })
      .catch(() => {
        toast('Unable To Send Password Reset Request!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }
};

const isValidEmail = ref(false);

watch(form, () => {
  isValidEmail.value = isValidEmailFormat(form.user.email);
});

const isValidEmailFormat = (email) => {
  // Regular expression for checking email format
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

</script>

<style scoped>
  #hero-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  @media only screen and (max-width: 1026px) {
    #hero-image {
        display: none;
    }

    #reset-password{
      grid-column: span 5 / span 3!important;
    }
}
</style>
