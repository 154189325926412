import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store/store";

import LoginView from '@/views/LoginView.vue'
import PasswordResetView from '@/views/PasswordResetView.vue'
import RegisterView from '@/views/RegisterView.vue'
import DashboardView from '@/views/DashboardView.vue'
import WorkoutView from '@/views/WorkoutView.vue'
import AssicCallingView from '@/views/AssicCallingView.vue'
import JoinMeetingView from '@/views/JoinMeetingView.vue'
import JoinMobileMeetingView from '@/views/JoinMobileMeetingView.vue'
import AboutView from '@/views/AboutView.vue'
import TermsAndConditionsView from '@/views/TermsAndConditions.vue'

import ActivityProfileView from '@/views/ActivityProfileView.vue'
import FitnessProfileView from '@/views/FitnessProfileView.vue'
import ConditioningProfileView from '@/views/ConditioningProfileView.vue'
import MedicalProfileView from '@/views/MedicalProfileView.vue'
import BodyProfileView from '@/views/BodyProfileView.vue'
import PainProfileView from '@/views/PainProfileView.vue'
import LabProfileView from '@/views/LabProfileView.vue'
import VitalsProfileView from '@/views/VitalsProfileView.vue'

import ProfileView from '@/views/ProfileView.vue'

import ForgotPasswordView from '@/views/ForgotPasswordView.vue'

const routes = [
	{
		path: '/',
		redirect: '/dashboard'
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/profile',
		name: 'Profile',
    meta: {
      requiresAuth: true
    },
		component: ProfileView
	},
	{
		path: '/register',
		name: 'Register',
		component: RegisterView
	},
  {
		path: '/reset-password',
		name: 'Reset Password',
		component: PasswordResetView
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPasswordView
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
		component: DashboardView
	},
	{
		path: '/workout',
		name: 'Workout',
    meta: {
      requiresAuth: true
    },
		component: WorkoutView
	},
  {
		path: '/join-mobile-meeting',
		name: 'Join Mobile Meeting',
		component: JoinMobileMeetingView,
  },
  {
		path: '/assic-calling',
		name: 'Assic Calling',
		component: AssicCallingView
	},
  {
		path: '/join-meeting',
		name: 'Join Meeting',
		component: JoinMeetingView,
    meta: {
      requiresAuth: true
    },
	},
  {
		path: '/terms-and-conditions',
		name: 'TermsAndConditions',
		component: TermsAndConditionsView
	},
	{
		path: '/about',
		name: 'AboutView',
    meta: {
      requiresAuth: true
    },
		component: AboutView
	},

	{
		path: '/activityprofile',
		name: 'ActivityProfile',
    meta: {
      requiresAuth: true
    },
		component: ActivityProfileView
	},
	{
		path: '/fitnessprofile',
		name: 'FitnessProfile',
    meta: {
      requiresAuth: true
    },
		component: FitnessProfileView
	},
	{
		path: '/bodyprofile',
		name: 'BodyProfile',
    meta: {
      requiresAuth: true
    },
		component: BodyProfileView
	},
	{
		path: '/painprofile',
		name: 'PainProfile',
    meta: {
      requiresAuth: true
    },
		component: PainProfileView
	},
	{
		path: '/conditioningprofile',
		name: 'ConditioningProfile',
    meta: {
      requiresAuth: true
    },
		component: ConditioningProfileView
	},
	{
		path: '/medicalprofile',
		name: 'MedicalProfile',
    meta: {
      requiresAuth: true
    },
		component: MedicalProfileView
	},

	{
		path: '/labprofile',
		name: 'LabProfile',
    meta: {
      requiresAuth: true
    },
		component: LabProfileView
	},
	{
		path: '/vitalsprofile',
		name: 'VitalsProfile',
    meta: {
      requiresAuth: true
    },
		component: VitalsProfileView
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const user = store.getters["auth/getUser"];
    if (user) {

      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next('/login');
    }
  } else {
    // Non-protected route, allow access
    next();
  }
})

export default router
