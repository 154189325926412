<template>
  <div class="body bg-[#F8F8F8] shadow-inner" id="bodyWrapper">
    <router-view></router-view>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { computed, watch } from "@vue/runtime-core";
  import base from "./services/api";

  const timeoutInMS = 3600000; // 60 minutes -> 60 * 60s * 1000ms
  let timeoutId;

  const store = useStore();
  const router = useRouter();
  const user = computed(() => store.getters["auth/getUser"]);

  const handleInactive = async () => {
    if(user.value != null){
      // Here you want to logout a user and/or ping your token
      store.dispatch("auth/setUser", null);
      router.push({ path: "/login" });
    }
  }

  const startSessionTimer = async () => {
    if(user.value != null){
    // setTimeout returns an ID (can be used to start or clear a timer)
    timeoutId = setTimeout(handleInactive, timeoutInMS);
    }
  }

  const resetSessionTimer = async () => {
    clearTimeout(timeoutId);
    startSessionTimer();
  }

  const setupSessionTimer  = async () => {
    document.addEventListener("keypress", resetSessionTimer, false);
    document.addEventListener("mousemove", resetSessionTimer, false);
    document.addEventListener("mousedown", resetSessionTimer, false);
    document.addEventListener("touchmove", resetSessionTimer, false);
    document.addEventListener("scroll", resetSessionTimer, false);
    document.addEventListener("click", resetSessionTimer, false);
    document.addEventListener("load", resetSessionTimer, false);

    startSessionTimer();
  }

  const disableSessionTimer  = async () => {
    document.removeEventListener("keypress", resetSessionTimer, false);
    document.removeEventListener("mousemove", resetSessionTimer, false);
    document.removeEventListener("mousedown", resetSessionTimer, false);
    document.removeEventListener("touchmove", resetSessionTimer, false);
    document.removeEventListener("scroll", resetSessionTimer, false);
    document.removeEventListener("click", resetSessionTimer, false);
    document.removeEventListener("load", resetSessionTimer, false);
  }

  watch(
    user,
    (v) => {
      if (v) {
        store.dispatch("fetchProfiles", v.token);
        base.defaults.headers.common[
          "Authorization"
        ] = `ASSIC ${user.value.token}`;

        setupSessionTimer();
      } else {
        disableSessionTimer();
      }
    },
    { immediate: true }
  );

</script>

<style>
@font-face {
  font-family: "PoppinsLight";
  src: url(./fonts/PoppinsLight.otf) format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: url(./fonts/PoppinsRegular.otf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url(./fonts/HelveticaNeueBold.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: url(./fonts/HelveticaNeueMedium.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueRegular";
  src: url(./fonts/HelveticaNeueRegular.ttf) format("truetype");
}

body {
  font-family: "PoppinsLight", Arial;
  color: #303030;
}

.headingOne {
  font-family: "HelveticaNeueMedium", Arial;
  color: #161922;
}

.headingTwo {
  font-family: "HelveticaNeueRegular", Arial;
  color: #161922;
}

.headingSections {
  font-family: "HelveticaNeueMedium", Arial;
  color: #161922;
}

/* @font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(../src/fonts/HelveticaNeue-Light.ttf) format("truetype");
}

.body {
  font-family: "Helvetica", Arial;
  font-size: 20px;
} */
 #bodyWrapper{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
 }
</style>
